import { useContext, createContext, useState } from "react";
import { IProp, SAction } from "../global";
import { LoadPanel } from 'devextreme-react/load-panel';
export type ConfigState = 'nothing' | 'scope' | 'ref-value' | 'secret-value' | 'processing';

export interface IConfigContext {
    selectedScopeIndex?: number
    setSelectedIndex: SAction<number>,

    state: ConfigState,
    setState: SAction<ConfigState>
}

export const ConfigContext = createContext<IConfigContext>({} as IConfigContext);

export function useConfigContext(): IConfigContext {
    const context = useContext(ConfigContext);
    if (context == null) throw new Error("Must be used within ConfigProvider");

    return context;
}

export default function ConfigProvider({ children }: IProp) {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [state, setState] = useState<ConfigState>('nothing');

    const value: IConfigContext = {
        state, setState,
        selectedScopeIndex: selectedIndex,
        setSelectedIndex
    }

    return (
        <ConfigContext.Provider value={value}>
            <LoadPanel visible={state === 'processing'} shading={true}
                message="Processing ..." shadingColor="rgba(0, 0, 0, .5)" />
            {children}
        </ConfigContext.Provider>
    );
}
