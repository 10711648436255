import { ContextMenu } from 'devextreme-react/context-menu';
import { Item as MenuItem } from 'devextreme/ui/context_menu'
import { useAuthContext, useAuthDispatch } from '../../../context/auth-context';

const menus: MenuItem[] = [
    { text: 'Signout', icon: 'login' }
]

export default function User() {
    const { signOut } = useAuthDispatch()
    const { user } = useAuthContext();

    return (
        <div className="logged-user-container mb-3">
            <ContextMenu target=".logged-user" items={menus} onItemClick={signOut} />
            <div className="logged-user">
                {user?.photoURL && <img className="avatar" src={user.photoURL} alt="Avatar" />}
                <div className="username">
                    <span>{user?.displayName}</span>
                </div>
            </div>
        </div >
    )
}