import { Form, SimpleItem, Label as ItemLabel } from 'devextreme-react/form';
import React, { useMemo } from 'react';
import { IScope, SAction, ScopeRecord } from '../../../global';
import { TextBox } from 'devextreme-react/text-box';

import { HtmlEditor } from 'devextreme-react/html-editor';
import CreateTitleComponent from './title';
import CreateTemplateComponent from './template';

const idOptions = { readOnly: true };

export interface IMetaProps {
    scope: IScope;
    title: React.RefObject<TextBox>,
    template: React.RefObject<HtmlEditor>,
    onSave: SAction<ScopeRecord>,
}

export default function Meta({ scope, title, template, onSave }: IMetaProps) {
    const TemplateComponent = useMemo(function () {
        return CreateTemplateComponent({ scope, ref: template });
    }, [scope, template])

    const TitleComponent = useMemo(function () {
        return CreateTitleComponent({ scope, ref: title, onSave });
    }, [scope, title, onSave])

    return (
        <Form formData={scope}>
            <SimpleItem dataField="id" editorOptions={idOptions}>
                <ItemLabel text='ID' />
            </SimpleItem>
            <SimpleItem dataField="title" component={TitleComponent} />
            <SimpleItem dataField="template" component={TemplateComponent} />
        </Form>
    )
}

