import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { CError } from "../global";

const config = process.env.REACT_APP_FIREBASE_CONFIG || "{}";

if (config.length <= 2) {
    throw new CError(
        "invalid_configuration",
        `Unable to parse FIREBASE_CONFIG from environment: '${process.env.REACT_APP_FIREBASE_CONFIG}'`
    );
}

export const app = firebase.initializeApp(JSON.parse(config));
