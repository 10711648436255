import { useContext, createContext, useState, useEffect } from "react";
import { signOut, onAuthStateChanged, FirebaseError, FirebaseUser, loginWithUi } from "../firebase";
import { SignInFailureCallback, SignInSuccessCallback } from "../firebase/auth";

// #region AuthContext

export interface IAuthContext {
    loading: boolean;
    user: FirebaseUser;
    error: FirebaseError;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function useAuthContext(): IAuthContext {
    const context = useContext(AuthContext);
    if (context == null) throw new Error("Must be used within AuthContext");

    return context;
}

// #endregion AuthContext

// #region AuthDispatch

export interface IAuthDispatch {
    signOut: () => Promise<void>;
    /**
     * Return true to auto redirect, false to self handle auth result
     */
    showAuthUi: (success?: SignInSuccessCallback, failure?: SignInFailureCallback) => void;
}

const AuthDispatch = createContext<IAuthDispatch>({} as IAuthDispatch);

export function useAuthDispatch(): IAuthDispatch {
    const context = useContext(AuthDispatch);
    if (context == null) throw new Error("Must be used within AuthProvider");

    return context;
}

// #endregion AuthDispatch

export interface AuthProviderProps {
    children?: any;
}

export default function AuthProvider({ children }: AuthProviderProps) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<FirebaseUser>(null);
    const [error, setError] = useState<FirebaseError>(null);

    useEffect(() => {
        onAuthStateChanged(
            (user) => {
                setUser(user);
                setLoading(false);
            },
            (error) => {
                setError(error);
                setLoading(false);
            }
        );
    }, []);

    const dispatcher: IAuthDispatch = {
        signOut: () => {
            setUser(null);
            return signOut();
        },
        showAuthUi: (success?: SignInSuccessCallback, failure?: SignInFailureCallback) => {
            loginWithUi(success, failure);
        },
    };

    return (
        <AuthContext.Provider value={{ loading, user, error }}>
            <AuthDispatch.Provider value={dispatcher}>
                {children}
            </AuthDispatch.Provider>
        </AuthContext.Provider>
    );
}
