import { ItemClickEvent, ItemDeletingEvent } from "devextreme/ui/list"
import { List } from 'devextreme-react/list';
import { useCallback, useContext } from "react";
import User from "./user";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { scopes as db } from "../../../firebase/realtime-db";
import { confirm } from 'devextreme/ui/dialog';

import { ContextMenu } from 'devextreme-react/context-menu';
import { Item as MenuItem } from 'devextreme/ui/context_menu'

import './sidebar.css'
import { logError, logWarn } from "../../../util/log";
import { useConfigContext } from "../../../context/config-context";
import { ItemInfo } from "devextreme/events";
import AddScopePopup from "../popup/add-scope-popup";
import { IScope } from "../../../global";
import { ScopesContext } from "../../../context/scope-context";
import { pinError, showError } from "../../notification";

const menus: (MenuItem & { id: string })[] = [
    { id: 'add-scope', text: 'Add', icon: 'add' },
]

export default function Sidebar() {

    const scopes = useContext(ScopesContext);
    const { selectedScopeIndex, state, setSelectedIndex, setState } = useConfigContext();

    const onScopeItemClick = useCallback(({ itemIndex }: ItemClickEvent<IScope>) => {
        if (typeof itemIndex === 'number') {
            setSelectedIndex(itemIndex)
        }
    }, [setSelectedIndex]);

    const onContextItemClick = useCallback(({ itemData }: ItemInfo) => {
        if (itemData.id === 'add-scope') {
            setState('scope')
            return;
        }

        logWarn('Unsupported action: ', itemData);
    }, [setState])

    const onClose = useCallback(() => {
        setState.bind(setState, 'nothing')
    }, [setState]);

    const onSubmitNewScope = useCallback((title: string, id: string) => {
        setState('processing');
        db.insert({ id, title })
            // No need dispatch({ action: 'add' }) here,
            // due to store auto refresh
            // .then(() => { })
            .catch(showError)
            .finally(() => setState('nothing'))
    }, [setState]);

    const onDeleteScope = useCallback((e: ItemDeletingEvent<IScope>) => {
        e.cancel = new Promise<boolean>(async (resolve, reject) => {
            const yes = await confirm('Are you sure', 'Delete scope')
            if (yes && e.itemData) {
                setState('processing');
                db.remove(e.itemData.id)
                    .catch((err) => {
                        reject(err);
                        logError(err);
                        pinError(err);
                    })
                    .finally(() => setState('nothing'))
            }

            resolve(!yes);
        });
    }, [setState])

    return (
        <div id="side-bar" className="h-100">
            <ContextMenu target="#side-bar" items={menus} onItemClick={onContextItemClick} />
            <AddScopePopup isShow={state === 'scope'} onClosed={onClose} onSubmit={onSubmitNewScope} />
            <User />
            <hr />
            {
                scopes == null
                    ? <div className="text-center"><LoadIndicator /></div>
                    : <List className="scope-list" indicateLoading={false}
                        selectionMode='single' allowItemDeleting={true} focusStateEnabled={false}
                        onItemDeleting={onDeleteScope} onItemClick={onScopeItemClick}
                        selectedIndex={selectedScopeIndex}
                        dataSource={scopes} displayExpr="title" keyExpr="id" />
            }
        </div >
    )
}
