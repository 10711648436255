import { useCallback, useEffect, useState } from "react";

export type AsyncTask<T> = () => Promise<T>;

export default function useAsync<T>(callback: AsyncTask<T>, ...dependencies: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [value, setValue] = useState<T | undefined>();

    const callbackMemoized = useCallback(() => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        try {
            callback().then(setValue).catch(setError);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [callback, ...dependencies]);

    useEffect(() => {
        callbackMemoized();
    }, [callbackMemoized]);

    return { loading, error, value };
}
