import notify from "devextreme/ui/notify";

export function showWarn(msg: string, pinned?: boolean) {
    show(msg, "warning", pinned);
}
export function showError(msg: string, pinned?: boolean) {
    show(msg, "error", pinned);
}
export function showSuccess(msg: string, pinned?: boolean) {
    show(msg, "success", pinned);
}
export function showInfo(msg: string) {
    show(msg, "info");
}

/**
 * Show error message which do not hide
 */
export function pinError(msg: string) {
    showError(msg, true);
}

export function show(msg: string, type: string, pinned?: boolean) {
    notify(
        {
            type: type,
            message: msg,
            displayTime: pinned === true ? 999999999 : 1000,
            animation: {
                show: { type: "fade", duration: 400, to: 1 },
                hide: { type: "fade", duration: 40, to: 0 },
            },
            width: "auto",
            closeOnClick: true,
            position: "bottom right",
        }
        // Uncomment to allow to stack messages
        // {
        //     direction: "up-push",
        //     position: "bottom right",
        // }
    );
}
