// Firebase App check
// !! Function call's order is important

import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { logWarn } from "../util/log";

const siteKey = process.env.REACT_APP_APPCHECK_SITE_KEY || "";

export function initAppCheck(app: any) {
    if (siteKey == null || siteKey.length === 0) {
        logWarn("App check disabled due to invalid site key");
        return;
    }

    return initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(siteKey),
        isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
    });
}
