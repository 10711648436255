import { DispatchActions, IScope, IValue } from "../../../../global";
import { Item as MenuItem } from "devextreme/ui/context_menu";

import { ValueDataSourceType } from "../../definition";
import { references, secrets } from "../../../../firebase/realtime-db";

export const REF_VALUE_MARK = "#";
export const SECRET_VALUE_MARK = "$";

export type CustomMenuItem = MenuItem & { scope: IScope; type: ValueDataSourceType };
export type DispatchParam = { action: DispatchActions; values: IValue[] };

export function valuesReducer(values: IValue[] | null, param: DispatchParam): IValue[] | null {
    const { action, values: paramValues } = param;
    switch (action) {
        case "init": {
            return paramValues;
        }

        case "added": {
            if (values) {
                return [...values, ...paramValues];
            }

            return null;
        }

        case "changed": {
            if (values) {
                if (values.length > 0) {
                    const target = paramValues[0];
                    return values.map((t) => (t.key === target.key ? target : t));
                }
            }

            return values;
        }

        case "deleted": {
            if (values) {
                if (values.length > 0) {
                    const { key } = paramValues[0];
                    return values.filter((t) => t.key !== key);
                }
            }

            return values;
        }

        default:
            throw Error("Unknown action: " + action);
    }
}

export function saveItem(type: ValueDataSourceType, scope: IScope, value: IValue) {
    switch (type) {
        case "reference":
            return references.update(scope.id, value);
        case "secret":
            return secrets.update(scope.id, value);
    }

    return Promise.reject("Unknown type: " + type);
}

export function deleteItem(type: ValueDataSourceType, scope: IScope, { key }: IValue) {
    switch (type) {
        case "reference":
            return references.remove(scope.id, key);
        case "secret":
            return secrets.remove(scope.id, key);
    }

    return Promise.reject("Unknown type: " + type);
}
