import { useEffect } from "react";
import { useAuthContext, useAuthDispatch } from "../../context/auth-context";
import { FIREBASE_AUTHUI_CSS } from "../../firebase/auth";
import { useExternalCss } from "../../hook/useScript";
import { useLocation, useNavigate } from 'react-router-dom';
import User from "../config/sidebar/user";

import "./login.css";
import usePageTitle from "../../hook/usePageTitle";

export function Login() {
    usePageTitle('Login');

    const { showAuthUi } = useAuthDispatch()
    const { loading: userLoading, user, error } = useAuthContext();
    const { loading: cssLoading } = useExternalCss(FIREBASE_AUTHUI_CSS);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (userLoading || cssLoading) return;
        if (user == null)
            showAuthUi((result: any) => {
                if (result && result.user) {
                    navigate(location.state?.from?.pathname || '/');
                }
                return false/* self handle */
            })
    }, [userLoading, cssLoading, user, location.state?.from?.pathname, navigate, showAuthUi]);

    if (user)
        return <User />

    if (error) {
        return <div>{error.message}</div>;
    }

    return null;
}

