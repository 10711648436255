import { SAction } from "../../global";

export const COPY_ACTION = "copy";

export type options = { key: string; value?: string; displayText?: string };
export type handlers = {
    onClick?: SAction<options | null>;
};

export function createReplacer({ key, value, displayText }: options, doc?: Document): HTMLElement {
    doc = doc || document;
    displayText = displayText || "";

    const span = doc.createElement("span");
    span.classList.add("mention-replacer");
    if (displayText.length === 0) span.classList.add("invisible");
    if (value) span.dataset.value = value;

    span.innerHTML = `<i class="content">${displayText}</i><span class="action"><a class="dx-icon-copy"></a></span>`;
    span.dataset.key = key;
    span.dataset.action = COPY_ACTION;
    span.dataset.display = displayText;

    return span;
}

export function setOption(key: string, value: string | null | undefined) {
    document.querySelectorAll<HTMLElement>(`.mention-replacer[data-key="${key}"]`).forEach((e) => {
        delete e.dataset.empty;
        delete e.dataset.value;

        if (value != null && value.length > 0) e.dataset.value = value;
        else {
            delete e.dataset.value;
            e.dataset.empty = "true";
        }
    });
}

export function getOptions(element: HTMLElement) {
    if (element) {
        const key = element.dataset.key || "";
        const value = element.dataset.value;
        const action = element.dataset.action;
        const displayText = element.dataset.display;

        return { key, action, value, displayText };
    }

    return null;
}

export function initReplacerEvents(handlers: handlers) {
    document.getElementById("root")?.addEventListener("click", (e) => {
        const target = (e.target as HTMLElement).closest<HTMLElement>("[data-action]");

        if (target && target.hasAttribute("data-value")) {
            if (typeof handlers.onClick === "function") {
                e.preventDefault();
                
                const options = getOptions(target);
                handlers.onClick.call(handlers.onClick, options);
            }
        }
    });
}
