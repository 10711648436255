import { useParams, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { scopes, templates } from "../../firebase/realtime-db";
import { showWarn } from "../notification";
import Processor from "./processor";

import './custom.css'
import Authorized from "../auth/authorized";
import usePageTitle from "../../hook/usePageTitle";
import { ScopeMeta } from "../../global";

export default function Home() {

    const { setTitle } = usePageTitle();
    const { scopeId } = useParams();
    const [template, setTemplate] = useState('');

    useEffect(() => {
        if (!scopeId) {
            showWarn('Not found !!!', true);
            setTitle('Not found')
            return;
        }

        const unsubScope = scopes.on(
            snap => {
                const meta = snap.val() as ScopeMeta
                if (meta)
                    setTitle(meta.title)
            },
            err => {
                setTitle((err as Error).name)
            },
            scopeId
        );

        const unsubTemplate = templates.on(
            snap => {
                const template = snap.val() as string;
                setTemplate(template);
            },
            err => {
                showWarn(err.message);
            },
            scopeId
        )

        return () => {
            unsubScope();
            unsubTemplate();
        }
    }, [scopeId, setTitle])

    if (!scopeId)
        return <Navigate to='/' />

    return (
        <Authorized>
            <Processor scopeId={scopeId} html={template} />
        </Authorized>
    )
}