
import { Popup } from 'devextreme-react/popup';
import { Form, SimpleItem, ButtonItem, GroupItem, RequiredRule, AsyncRule } from 'devextreme-react/form';

import { useCallback, useMemo, useRef, FormEventHandler } from 'react';
import { useConfigContext } from '../../../context/config-context';
import { IScope, IValue, SAction } from '../../../global';
import { ValueDataSourceType } from '../definition';
import { ValidationCallbackData } from 'devextreme/common';
import { references, secrets } from '../../../firebase/realtime-db';

export interface IAddValuePopup {
    scope?: IScope,
    type?: ValueDataSourceType
    onClose: Function,
    onSave: SAction<IValue>
}

export default function AddValuePopup({ scope, type, onClose, onSave }: IAddValuePopup) {
    const { state: addingContext } = useConfigContext();
    const form = useRef<Form>(null);
    const title = useMemo(() => ['Add', type, 'value'].join(' '), [type])
    const shouldShow = useMemo(() => {
        switch (type) {
            case 'reference':
                return addingContext === 'ref-value';
            case 'secret':
                return addingContext === 'secret-value';
        }
    }, [type, addingContext]);

    const checkKeyExists = async ({ value }: ValidationCallbackData) => {
        if (scope) {
            switch (type) {
                case 'reference': {
                    const val = await references.exists(scope.id, value);
                    return val === false;
                }
                case 'secret': {
                    const val = await secrets.exists(scope.id, value);
                    return val === false;
                }
            }
        }

        throw new Error("Unknown error");
    }

    const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
        const key = form.current?.instance.getEditor('key')?.option('value');
        const value = form.current?.instance.getEditor('value')?.option('value');

        onSave.call(onSave, { key, value });
        e.preventDefault();
    }, [onSave])

    const focusEditor = () => {
        form.current?.instance.getEditor('key')?.focus();
    }

    const resetForm = () => {
        form.current?.instance.resetValues();
    }

    return (
        <Popup visible={shouldShow} height="auto" onHidden={onClose.bind(onClose)} title={title}
            onShowing={resetForm} onShown={focusEditor}>
            <form onSubmit={onSubmit}>
                <Form ref={form} formData={{ key: '', value: '' }} focusStateEnabled={true}>
                    <GroupItem>
                        <SimpleItem dataField='key'>
                            <RequiredRule />
                            <AsyncRule message='Key was existed' ignoreEmptyValue={true}
                                validationCallback={checkKeyExists} />
                        </SimpleItem>
                        <SimpleItem dataField='value'>
                            <RequiredRule />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={2} cssClass="popup-footer-buttons">
                        <ButtonItem cssClass='footer-button' buttonOptions={{ icon: 'save', hint: 'Save value', useSubmitBehavior: true }} />
                        <ButtonItem cssClass='footer-button' itemType='button' buttonOptions={{ icon: 'close', hint: 'Cancel changes', onClick: onClose.bind(onClose) }} />
                    </GroupItem>
                </Form>
            </form>
        </Popup>
    )
}