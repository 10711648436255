import { IValue, SAction } from "../../../../global"
import { useRef, useState } from "react";
import Validator, { RequiredRule } from 'devextreme-react/validator';

import { TextBox, Button } from 'devextreme-react/text-box';
import { TextBoxType } from 'devextreme/ui/text_box';
import { dxComponentProp, ValueDataSourceType } from "../../definition";
import { REF_VALUE_MARK, SECRET_VALUE_MARK } from "./common";


export default function CreateValueItem(options: { type: ValueDataSourceType, onSave: SAction<IValue> }) {
    return function ValueItem(props: dxComponentProp<IValue>) {
        const { data } = props;
        const { onSave, type } = options;
        const valueRef = useRef<TextBox>(null);
        const [valueMode, setValueModel] = useState<TextBoxType>(type === 'secret' ? 'password' : 'text')

        const onSaveButtonClick = (e: any) => {
            const result = e.validationGroup.validate()
            if (result.isValid === true) {
                const textValue = valueRef.current?.instance.option('value')
                if (textValue) {
                    onSave.call(onSave, { key: data.key, value: textValue });
                }
            }
        }

        const toggleValueIcon = valueMode === 'password' ? 'eyeopen' : 'eyeclose'
        const toggleValueMode = () => setValueModel(pre => pre === 'password' ? 'text' : 'password')

        return (
            <div className="d-flex" key={data.key}>
                <TextBox readOnly={true} width='2.25rem' className="text-center value-header"
                    focusStateEnabled={false} value={type === 'reference' ? REF_VALUE_MARK : SECRET_VALUE_MARK} />
                <TextBox readOnly={true} width='20%' value={data.key} className="value-header"
                    focusStateEnabled={false} />
                <div className="p-1"></div>
                <TextBox ref={valueRef} defaultValue={data.value} width='75%'
                    validationMessagePosition='left' mode={valueMode}>
                    <Validator>
                        <RequiredRule />
                    </Validator>
                    {type === 'secret' && <Button name="Show" location="after" options={{ icon: toggleValueIcon, onClick: toggleValueMode }} />}
                    <Button name="Save" location="after" options={{ icon: 'save', onClick: onSaveButtonClick }} />
                </TextBox>
            </div >
        )
    }
}