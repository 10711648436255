import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context";
import { useLocation } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';

/**
 * An authorized component which required user login to use
 */
export default function Authorized({ children }: any) {
    const { loading, user, error } = useAuthContext();
    const location = useLocation();

    if (loading)
        return <LoadPanel visible={true} message='Authenticating ...' />

    if (error)
        return <div>{error.message}</div>

    if (user == null)
        return <Navigate to="/login" replace state={{ from: location }} />

    return children;
}
