import { useEffect, useState } from "react";
import { APP_NAME } from "../global";

const createTitle = (title?: string | null | undefined) => APP_NAME + (title ? " | " + title : "");

export default function usePageTitle(title?: string | null | undefined) {
    const [currentTitle, setTitle] = useState(APP_NAME);

    useEffect(() => {
        if (title) setCurrentTitle(title);
    }, [title]);

    const setCurrentTitle = (title?: string | null | undefined) => setTitle(createTitle(title));

    useEffect(() => {
        document.title = currentTitle;
    }, [currentTitle]);

    return { title: currentTitle, setTitle: setCurrentTitle };
}
