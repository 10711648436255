
import { IProp, IScope, IValue, } from "../../../../global"
import { List } from 'devextreme-react/list';
import { useCallback, useMemo, useReducer, useState } from "react";

import { ContextMenu } from 'devextreme-react/context-menu';
import { ItemDeletingEvent } from 'devextreme/ui/list'
import { confirm } from 'devextreme/ui/dialog';

import { ItemInfo } from "devextreme/events";
import { useConfigContext } from "../../../../context/config-context";
import AddValuePopup from "../../popup/add-value";
import { pinError } from "../../../notification";
import { logError } from "../../../../util/log";
import { ValueDataSourceType } from "../../definition";
import CreateValueItem from "./value-item";
import { CustomMenuItem, deleteItem, saveItem, valuesReducer } from "./common";

export interface ICoreValuesProps extends IProp {
    mark: string,
    scope: IScope,
    type: ValueDataSourceType,
}

export default function Values(props: ICoreValuesProps) {

    const { mark, scope, type } = props;
    const { setState } = useConfigContext();
    const [values, dispatch] = useReducer(valuesReducer, type === "reference" ? scope.refValues : scope.secretValues);

    const [showingScope, setShowingScope] = useState<IScope | undefined>(undefined);
    const [showingType, setShowingType] = useState<ValueDataSourceType | undefined>(undefined);

    const id = useMemo(() => ['value-list', scope.id, type].join('-'), [scope.id, type])
    const items = useMemo<CustomMenuItem[]>(() => [{ text: 'Add', icon: 'add', type, scope }], [type, scope]);

    const onItemClick = useCallback((e: ItemInfo) => {
        const data = e.itemData as CustomMenuItem;

        setShowingType(data.type);
        setShowingScope(data.scope);

        setState(data.type === "secret" ? 'secret-value'
            : data.type === 'reference' ? 'ref-value'
                : 'nothing');
    }, [setState])

    function onDeleteItem(e: ItemDeletingEvent<IValue, any>) {
        e.cancel = new Promise<boolean>(async (resolve, reject) => {
            const yes = await confirm('Are you sure', 'Delete value')
            if (yes && e.itemData) {
                setState('processing')
                deleteItem(type, scope, e.itemData)
                    .catch((err) => {
                        reject(err);
                        logError(err);
                        pinError(err);
                    })
                    .finally(() => setState('nothing'))
            }

            resolve(!yes);
        });
    }

    const reset = useCallback(() => {
        setShowingType(undefined);
        setShowingScope(undefined);
        setState('nothing');
    }, [setState]);

    const onAdd = (value: IValue) => {
        setState('processing')
        saveItem(type, scope, value)
            .then(() => dispatch({ action: 'added', values: [value] }))
            .catch(pinError)
            .finally(() => reset())
    }

    const onSave = (value: IValue) => {
        setState('processing')
        saveItem(type, scope, value)
            .then(() => dispatch({ action: 'changed', values: [value] }))
            .catch(pinError)
            .finally(() => reset())
    }

    return (
        <>
            <AddValuePopup onClose={reset} onSave={onAdd} scope={showingScope} type={showingType} />
            <ContextMenu target={'#' + id} items={items} onItemClick={onItemClick} />
            <List key={id} id={id} allowItemDeleting={true}
                indicateLoading={true} onItemDeleting={onDeleteItem}
                dataSource={values} itemComponent={CreateValueItem({ type, onSave })} elementAttr={{ mark }} />
        </>
    )
}
