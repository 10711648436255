import Authorized from "../auth/authorized";
import Sidebar from "./sidebar/sidebar";
import ResponsiveBox, { Item, Location } from 'devextreme-react/responsive-box';
import Main from "./main/main";
import SpeedDialAction from 'devextreme-react/speed-dial-action';

import ConfigProvider from "../../context/config-context";
import ScopeProvider from "../../context/scope-context";
import usePageTitle from "../../hook/usePageTitle";
import { useAuthDispatch } from "../../context/auth-context";
import { useLocalStorage } from "../../hook/useStorage";

export default function Configuration() {
    usePageTitle('Configuration');

    const { signOut } = useAuthDispatch()
    const [showSidebar, setSidebarVisible] = useLocalStorage('sidebar-visible', true)
    const toggleSidebar = () => setSidebarVisible(pre => !pre);

    return (
        <Authorized>
            <ConfigProvider>
                <ScopeProvider>
                    <SpeedDialAction icon={showSidebar ? 'hidepanel' : 'showpanel'} label={showSidebar ? 'Hide sidebar' : 'Show sidebar'} onClick={toggleSidebar} />
                    <SpeedDialAction icon="login" label="Sign out" onClick={signOut} />

                    <ResponsiveBox rows={[{/* 1 row */ }]} cols={[{/* And 4 columns */ }, {}, {}, {}]}>
                        <Item visible={showSidebar}>
                            <Location row={0} col={0} />
                            <div className="pl-1 pr-2 mt-2 h-100">
                                <Sidebar />
                            </div>
                        </Item>
                        <Item>
                            <Location row={0} col={1} colspan={3} />
                            <div className="pl-2 pr-1 mt-2 h-100">
                                <Main />
                            </div>
                        </Item>
                    </ResponsiveBox>
                </ScopeProvider>
            </ConfigProvider>
        </Authorized >
    )
}