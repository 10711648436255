import React, { useEffect, useState } from 'react';
import { IScope } from '../../../global';

import { HtmlEditor, Toolbar, Item, TableContextMenu, Mention, TableResizing } from 'devextreme-react/html-editor';
import { LoadPanel, Position } from 'devextreme-react/load-panel';
import { templates } from "../../../firebase/realtime-db";
import { logWarn } from '../../../util/log';
import { REF_VALUE_MARK, SECRET_VALUE_MARK } from './value/common';

const sizes = ['1rem', '1.25rem', '1.5rem', '2rem', '2.5rem', '3rem']
const headers = [false, 1, 2, 3, 4, 5];

export default function CreateTemplateComponent({ scope, ref }: {
    scope: IScope,
    ref: React.RefObject<HtmlEditor>
}) {
    return function TemplateComponent() {
        const [html, setHtml] = useState<string>('');
        const [templateLoading, setTemplateLoading] = useState(true);

        useEffect(() => {
            if (ref && ref.current && html) {
                ref.current.instance.option('value', html);
            }
        }, [html]);

        // Go go ...
        useEffect(() => {
            templates.getByScope(scope.id)
                .then(setHtml)
                .catch(logWarn)
                .finally(() => setTemplateLoading(false))
        }, []);

        return (
            <>
                <LoadPanel visible={templateLoading} shading={true} shadingColor=''
                    container={`.template-editor-${scope.id}`}>
                    <Position my='bottom' at='center' of={`.template-editor-${scope.id}`} />
                </LoadPanel>
                <HtmlEditor ref={ref} className={`template-editor template-editor-${scope.id}`} defaultValue={html} valueType="html">
                    <TableContextMenu enabled={true} />
                    <TableResizing enabled={true} />
                    <Mention dataSource={scope.refValues} marker={REF_VALUE_MARK} valueExpr='key' displayExpr='value' />
                    <Mention dataSource={scope.secretValues} marker={SECRET_VALUE_MARK} valueExpr='key' displayExpr='key' />
                    <Toolbar multiline={true}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="clear" />
                        <Item name="separator" />

                        <Item name="background" />
                        <Item name="color" />
                        <Item name="separator" />

                        <Item name="increaseIndent" />
                        <Item name="decreaseIndent" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />

                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="subscript" />
                        <Item name="superscript" />
                        <Item name="separator" />

                        <Item name="link" />
                        <Item name="blockquote" />
                        <Item name="codeBlock" />
                        <Item name="separator" />

                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />

                        <Item name="insertTable" />
                        <Item name="insertHeaderRow" />
                        <Item name="deleteTable" />
                        <Item name="separator" />

                        <Item name="size" acceptedValues={sizes} />
                        <Item name="header" acceptedValues={headers} />
                        <Item widget='dxButton' options={{ icon: 'fullscreen', hint: 'Fullscreen mode' }} />
                    </Toolbar>
                </HtmlEditor>
            </>
        );
    }
}
