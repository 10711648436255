// Firebase authentication
// !! Function call's order is important

import "firebase/compat/auth";
import { connectAuthEmulator, Auth } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";

export const auth = firebase.auth();

const authEmulator = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
if (authEmulator && process.env.NODE_ENV !== "development")
    connectAuthEmulator(auth as unknown as Auth, authEmulator);

export const authUi = new firebaseui.auth.AuthUI(auth);

export type SignInSuccessCallback = (result: any, redirectUrl?: string) => boolean;

export type SignInFailureCallback = (error: firebaseui.auth.AuthUIError) => Promise<void> | void;

/**
 * Style sheet for firebase autn UI
 */
export const FIREBASE_AUTHUI_CSS =
    "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css";

export default function showAuthUi(
    authUi: firebaseui.auth.AuthUI,
    signInFailure?: SignInFailureCallback,
    signInSuccessWithAuthResult?: SignInSuccessCallback
) {
    // - Element with id 'firebase-ui' must be already exists in doccument
    // - Link css (version should be matched with package dependencies)
    //  https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css

    const id = "firebase-ui";
    let container = document.getElementById(id);
    if (container == null) {
        container = document.createElement("div");
        container.id = id;
        document.body.append(container);
    }

    authUi.start(container, {
        signInFlow: "popup",
        signInOptions: [
            // List provider's id.
            // Setup in firebase console -> Authentication
            //  -> signin method -> Sign-in providers
            firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInFailure,
            signInSuccessWithAuthResult,
        },
    });
}
