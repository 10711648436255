import "firebase/compat/auth";

import firebase from "firebase/compat/app";
import { CError } from "../global";
import showAuthUi, { auth, authUi, SignInFailureCallback, SignInSuccessCallback } from "./auth";
import { app } from "./_internal";
import { initAppCheck } from "./appcheck";

// Why does firebase fail when remove these lines ????
const config = process.env.REACT_APP_FIREBASE_CONFIG || "{}";
if (config.length <= 2) {
    throw new CError(
        "invalid_configuration",
        `Unable to parse FIREBASE_CONFIG from environment: '${process.env.REACT_APP_FIREBASE_CONFIG}'`
    );
}

initAppCheck(app);

export type FirebaseUser = firebase.User | null;
export type FirebaseError = firebase.auth.Error | null;
export const signOut = auth.signOut.bind(auth);
export function onAuthStateChanged(
    nextOrObserver: firebase.Observer<any> | ((a: firebase.User | null) => any),
    error?: (a: firebase.auth.Error) => any,
    completed?: firebase.Unsubscribe
) {
    auth.onAuthStateChanged.call(auth, nextOrObserver, error, completed);
}
/**
 * @param success success callback
 * @param failure failure callback
 */
export function loginWithUi(success?: SignInSuccessCallback, failure?: SignInFailureCallback) {
    showAuthUi(authUi, failure, success);
}
