import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { ValidationStatus } from 'devextreme/common';

import { useMemo, useRef, useState } from 'react';
import { spaceToDash } from '../../../util/text-util';
import { NativeEventInfo } from 'devextreme/events';
import dxTextBox from 'devextreme/ui/text_box';
import { SAction, Action, DAction } from '../../../global';
import { scopes } from '../../../firebase/realtime-db';
import { logError } from '../../../util/log';
import { showError } from '../../notification';

export interface IAddScopePopup {
    isShow: boolean,
    onClosed: Action,
    onSubmit: DAction<string, string>
}

function createButtons(options: { onSave: SAction<HTMLInputElement>, onCancel: Action }) {
    const { onCancel, onSave } = options;

    /**
     * @type {import('devextreme/ui/text_box/ui.text_editor.base').dxTextEditorButton[]}
     */
    const buttons = [
        {
            name: 'save', options: {
                icon: 'save',
                hint: 'Save scope',
                onClick: () => {
                    const input = document.getElementById('new-scope-input')?.querySelector('input')
                    if (input != null)
                        onSave.call(onSave, input);
                }
            }
        },
        {
            name: 'cancel', options: {
                icon: 'close',
                hint: 'Cancel changes',
                onClick: onCancel.bind(onCancel)
            }
        },
    ]

    return buttons;
}
export default function AddScopePopup({ isShow, onClosed, onSubmit }: IAddScopePopup) {
    const [validState, setValidState] = useState<ValidationStatus>('valid');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [inputValue, setInputValue] = useState<string | null | undefined>(null);
    const scopeInput = useRef<TextBox>(null);

    const buttons = useMemo(() => createButtons({
        onCancel: onClosed,
        onSave: input => {
            const isInvalid = input.value == null || input.value.length === 0;
            setValidState(isInvalid ? 'invalid' : 'invalid');
            setErrorMsg(isInvalid ? 'Title is required' : '');
            if (isInvalid)
                return;

            const scopeId = spaceToDash(input.value).toLowerCase();

            setValidState('pending');
            scopes.exists(scopeId)
                .then((isExists) => {
                    setValidState(isExists ? 'invalid' : 'valid');
                    setErrorMsg(isExists ? 'Scope already exists' : '');

                    if (isExists === false) {
                        onSubmit.call(onSubmit, input.value, scopeId);
                    }
                })
                .catch(err => {
                    logError(err);
                    showError(err);
                    setValidState('invalid');
                })
        }
    }), [onClosed, onSubmit]);

    const reset = () => {
        setInputValue(null);
        setValidState('valid')
        setErrorMsg('');
    }

    const onInput = ({ event }: NativeEventInfo<dxTextBox, Event>) => {
        var e = event?.target as HTMLInputElement;
        if (e)
            setInputValue(e.value)
    }

    const focusEditor = () => {
        scopeInput.current?.instance.focus();
    }

    return (
        <Popup visible={isShow} minHeight="180px" height="auto" showTitle={false}
            showCloseButton={false} onShowing={reset} onShown={focusEditor} >
            <div className='py-2'>
                <TextBox readOnly={true} value={spaceToDash(inputValue || '').toLowerCase()}
                    label="Id" height={40} focusStateEnabled={false} />
            </div>
            <div className='py-2'>
                <TextBox ref={scopeInput} id='new-scope-input' width="100%" label="Title"
                    height={40} inputAttr={{ required: true }} value={inputValue || ''}
                    onInput={onInput}
                    focusStateEnabled={true} placeholder="Scope title"
                    validationStatus={validState} validationError={{ message: errorMsg }}
                    name="new-scope" buttons={buttons} />
            </div>
        </Popup>
    )
}
