import { useEffect, useState } from "react";
import { secrets } from "../../firebase/realtime-db";
import { REF_VALUE_MARK, SECRET_VALUE_MARK } from "../config/main/value/common";
import { showInfo, showWarn } from "../notification";
import { createReplacer, initReplacerEvents, setOption } from "./replacer";

export default function Processor(props: { scopeId: string, html: string | null }) {
    const { scopeId, html } = props;
    const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        initReplacerEvents({
            onClick: (options) => {
                if (options?.value) {
                    navigator.clipboard.writeText(options.value);
                    showInfo('Copied!');
                }
            }
        })
    }, [])

    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(`<div class='template-wrapper'>${html}</div>`, 'text/html');
        const _wrapper = doc.querySelector('.template-wrapper') as HTMLDivElement;

        const tobeFetchKeys = new Set<string>();
        _wrapper.querySelectorAll('.dx-mention')
            .forEach(element => {
                const type = element.getAttribute('data-marker');
                const key = element.getAttribute('data-id') || '';
                const value = element.getAttribute('data-mention-value') || '';

                switch (type) {
                    case REF_VALUE_MARK: {
                        const span = createReplacer({ key, value, displayText: value }, doc);
                        element.after(span);
                    } break;

                    case SECRET_VALUE_MARK: {
                        const span = createReplacer({ key }, doc);
                        element.after(span)

                        tobeFetchKeys.add(key);
                    } break;

                    default:
                        break;
                }
                element.classList.add('d-none');
            })

        const cleanupFns = Array.from(tobeFetchKeys).map(k => {
            return secrets.on(
                snap => {
                    setOption(k, undefined);
                    // Timeout 300 ms, equals css effect duration
                    // to see gui changes

                    const value = snap.val();
                    setTimeout(() => setOption(k, value), 300);
                },
                err => {
                    showWarn(err.message);
                },
                scopeId + '/' + k
            )
        })

        setWrapper(_wrapper);
        return () => {
            cleanupFns.forEach(f => f());
        }

    }, [html, scopeId])

    return (
        <div className="dx-htmleditor-content" dangerouslySetInnerHTML={{ __html: wrapper?.innerHTML || '' }}></div>
    )
}
