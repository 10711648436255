import { createContext, useEffect, useReducer } from 'react';
import { DispatchActions, IScope, SAction, Scope, ScopeRecord } from '../global';
import { scopes as db } from '../firebase/realtime-db';
import { logError } from '../util/log';
import { pinError } from '../GUI/notification';

export const ScopesContext = createContext<IScope[] | null>(null);
export const ScopesDispatchContext = createContext<SAction<DispatchParam> | null>(null);

type DispatchParam = {
    values: ScopeRecord[],
    action: DispatchActions,
}

function scopesReducer(scopes: IScope[] | null, param: DispatchParam): IScope[] | null {
    const { action, values } = param;

    switch (action) {
        case 'init': {
            return values.map(v => new Scope(v.id, v.title));
        }

        case 'added': {
            if (scopes) {
                return [...scopes, ...values.map(v => new Scope(v.id, v.title))];
            }

            return null;
        }

        case 'changed': {
            if (scopes) {
                if (values.length > 0) {
                    const target = values[0];
                    return scopes.map(t => t.id === target.id ? new Scope(target.id, target.title) : t);
                }
            }

            return scopes;
        }

        case 'deleted': {
            if (scopes) {
                if (values.length > 0) {
                    const { id } = values[0];
                    return scopes.filter(t => t.id !== id);
                }
            }

            return scopes;
        }

        default:
            throw Error('Unknown action: ' + action);
    }
}

export default function ScopeProvider({ children }: { children: any }) {
    const [scopes, dispatch] = useReducer(scopesReducer, null);

    useEffect(() => {
        db.on(
            snapshot => {
                const _temp = snapshot.val();
                if (_temp == null) {
                    // Empty collection
                    dispatch({ action: 'init', values: [] });
                    return;
                }

                const values = Object.keys(_temp).map(k => ({ id: k, title: _temp[k].title }));
                dispatch({ action: 'init', values });
            },
            err => {
                dispatch({ action: 'init', values: [] });
                logError(err);
                pinError(err.message);
            }
        )

    }, [])

    return (
        <ScopesContext.Provider value={scopes}>
            <ScopesDispatchContext.Provider value={dispatch}>
                {children}
            </ScopesDispatchContext.Provider>
        </ScopesContext.Provider>
    )
}