import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import 'devextreme/dist/css/dx.dark.css';

import AuthProvider from "./context/auth-context";
import Home from "./GUI/home";
import { Login } from "./GUI/auth";
import Configuration from "./GUI/config";

import './app.css'

export default function App() {
	return (
		<AuthProvider>
			<Routes>
				<Route path="/" element={<Outlet />}>
					<Route index element={<Home />} />
					<Route path="/scopes/:scopeId" element={<Home />} />
					<Route path="login" element={<Login />} />
					<Route path="config" element={<Configuration />} />
					<Route path="*" element={<Navigate to='/' />} />
				</Route>
			</Routes>
		</AuthProvider>
	)
}
