import TabPanel, { Item } from "devextreme-react/tab-panel";
import { MultiView } from 'devextreme-react/multi-view';
import { useCallback, useContext, useEffect, useRef } from 'react'
import { HtmlEditor } from 'devextreme-react/html-editor';
import { TextBox } from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { TitleRenderedEvent } from 'devextreme/ui/tab_panel';

import './main.css'
import { useConfigContext } from "../../../context/config-context";
import { dxComponentProp } from "../definition";
import { IScope, ScopeRecord } from "../../../global";
import { ScopesContext } from "../../../context/scope-context";
import { scopes, templates } from "../../../firebase/realtime-db";
import { pinError } from "../../notification";
import Meta from "./meta";
import ReferenceValues from "./value/reference-values";
import SecretValues from "./value/secret-values";
import { REF_VALUE_MARK, SECRET_VALUE_MARK } from "./value/common";


export interface IMainProps {
}

export default function Main(props: IMainProps) {
    const scopes = useContext(ScopesContext);
    const { selectedScopeIndex } = useConfigContext();

    // const calcIndex = () => {
    //     // Share with SideBar component
    //     if (typeof selectedScopeIndex !== 'number') return 0;
    //     if (scopes == null) return 0;

    //     if (selectedScopeIndex < 0) return 0;
    //     if (selectedScopeIndex >= scopes.length) return scopes.length - 1;

    //     return selectedScopeIndex;
    // }

    if (scopes == null)
        return <div className="text-center"><LoadIndicator /></div>

    return (
        <MultiView id="main" deferRendering={true}
            dataSource={scopes} itemComponent={SingleScope}
            selectedIndex={selectedScopeIndex} swipeEnabled={false} />
    )
}

function SingleScope(prop: dxComponentProp<IScope>) {
    const { data: scope } = prop;

    const { setState } = useConfigContext();
    const tabRef = useRef<TabPanel>(null);
    const titleRef = useRef<TextBox>(null);
    const templateRef = useRef<HtmlEditor>(null);

    useEffect(() => {
        scope.loadValues()
            .then(() => {
                // Using values for Mention's datasource
                // -> repaint this component (or at least <Template />)
                //    to assign datasource's value

                //    Due to the component does not re-render
                //    and datasource do not see anything before first render
                tabRef.current?.instance.repaint();
            })
            .catch(pinError)
    }, [scope])

    const onSaveScope = useCallback(({ id }: ScopeRecord) => {
        const title = titleRef.current?.instance.option('value');
        const template = templateRef.current?.instance.option('value');

        setState('processing');
        Promise.all([scopes.update(id, title), templates.update(id, template)])
            .catch(pinError)
            .finally(() => setState('nothing'))
    }, [setState])

    const onTitleRendered = useCallback(({ itemData, itemElement }: TitleRenderedEvent) => {
        const mark = itemData.icon as string;
        const title = itemElement?.querySelector('.dx-tab-text');
        if (title && mark) {
            title.innerHTML = title.innerHTML + `<sup class='title-sup'>${mark}</sup>`;
        }
    }, [])

    return (
        <TabPanel className="main-tab" ref={tabRef} focusStateEnabled={false} deferRendering={true} onTitleRendered={onTitleRendered}>
            <Item title="Scope" key={scope.id + '-meta'}>
                <div className="p-3">
                    <Meta scope={scope} key={scope.id} onSave={onSaveScope}
                        title={titleRef} template={templateRef} />
                </div>
            </Item>

            <Item title='Reference' icon={REF_VALUE_MARK} key={scope.id + '-ref'}>
                <div className="py-3">
                    <ReferenceValues scope={scope} key={scope.id} />
                </div>
            </Item>
            <Item title='Secret' icon={SECRET_VALUE_MARK} key={scope.id + '-secret'}>
                <div className="py-3">
                    <SecretValues scope={scope} key={scope.id} />
                </div>
            </Item>
        </TabPanel>
    )
}