export const logInfo = console.info;
export const logWarn = console.warn;
export const logError = console.error;

const logger = {
    info: logInfo,
    warn: logWarn,
    error: logError,
};

export default logger;
