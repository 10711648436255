import useAsync from "./useAsync";

export function useScript(url: string) {
    return useAsync<Event>(() => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;

        return new Promise((resolve, reject) => {
            script.addEventListener("load", resolve);
            script.addEventListener("error", reject);
            document.body.appendChild(script);
        });
    }, [url]);
}

export function useExternalCss(href: string) {
    return useAsync<void>(() => {
        return new Promise<void>((resolve, reject) => {
            let link = document.head.querySelector<HTMLLinkElement>(`link[href="${href}"]`);
            if (link) {
                // Already loaded
                resolve();
                return;
            }

            link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = href;

            link.addEventListener("load", () => resolve());
            link.addEventListener("error", reject);
            document.head.appendChild(link);
        });
    }, [href]);
}
