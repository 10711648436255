import React, { useMemo } from 'react';
import { SAction, ScopeRecord } from '../../../global';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { dxButtonOptions } from 'devextreme/ui/button';

export default function CreateTitleComponent({ scope, ref, onSave }: {
    scope: ScopeRecord,
    ref: React.RefObject<TextBox>,
    onSave: SAction<ScopeRecord>
}) {
    return function TitleComponent() {
        const titleOptions = useMemo<dxButtonOptions>(() => ({
            icon: 'save',
            hint: 'Save title and template',
            stylingMode: 'outlined',
            type: 'success',
            onClick: () => onSave.call(onSave, scope)
        }), []);

        return (
            <TextBox ref={ref} defaultValue={scope.title}>
                <TextBoxButton name='save' location='after' options={titleOptions} />
            </TextBox>
        )
    }
}