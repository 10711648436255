import { references, secrets } from "./firebase/realtime-db";

export const APP_NAME = process.env.REACT_APP_GLOBAL_NAME || "Web Widget";
export type Action = () => void;
export type Function<TOUT> = () => TOUT;
export type SAction<T> = (arg: T) => void;
export type SFunction<TIN, TOUT> = (arg: TIN) => TOUT;
export type DAction<T1, T2> = (arg1: T1, arg2: T2) => void;
export type DFunction<TIN1, TIN2, TOUT> = (arg1: TIN1, arg2: TIN2) => TOUT;
export type DispatchActions = "init" | "added" | "changed" | "deleted";
/**
 * Simple prop of react component
 */
export interface IProp {
    children?: any;
}
/**
 * Define single value (ref or secret) in list with belong to scope
 */
export interface IValue {
    key: string;
    value?: string;
}

export type ScopeMeta = { title: string };
export type ScopeRecord = { id: string; title: string };
export interface IScope extends ScopeRecord {
    get refValues(): IValue[];
    get secretValues(): IValue[];
    /**
     * Load refValues & secretValues
     */
    loadValues(): Promise<void>;
}

export class Scope implements IScope {
    id: string;
    title: string;
    private _refValues: IValue[] | null | undefined;
    private _secretValues: IValue[] | null | undefined;

    constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
        this._refValues = undefined;
        this._secretValues = undefined;
    }

    get refValues(): IValue[] {
        return this._refValues || [];
    }

    get secretValues(): IValue[] {
        return this._secretValues || [];
    }

    async loadValues() {
        this._refValues = await references.getByScope(this.id);
        this._secretValues = await secrets.getByScope(this.id);
    }
}

/**
 * Custom error
 */
export class CError extends Error {
    constructor(name: string, msg: string = "") {
        super(msg);
        this.name = name;
    }
}

export default global;
