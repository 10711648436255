// Firebase realtime database
// !! Function call's order is important

import {
    getDatabase,
    DataSnapshot,
    ref,
    onValue,
    get,
    set,
    query,
    update,
    remove,
    orderByChild,
    equalTo,
    connectDatabaseEmulator,
} from "firebase/database";

import { DUPLICATED, INVALID_INPUT_DATA, NOTFOUND } from "./definition";
import { CError, IScope, IValue, SAction, Scope, ScopeMeta, ScopeRecord } from "../global";
import "./_internal";

const db = getDatabase();
const dbEmulator = process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST;
if (dbEmulator && process.env.NODE_ENV !== "development") {
    const url = new URL(dbEmulator);
    connectDatabaseEmulator(db, url.hostname, parseInt(url.port));
}

/**
 * Mark a scope as removed (do not fetch to gui)
 */
const removed_indices = { removed: true };

const PREFIX = process.env.REACT_APP_COLLECTION_PREFIX || "";
const COLLECTION_META = ["/", PREFIX, "metas"].join("");
const COLLECTION_TEMPLATE = ["/", PREFIX, "templates"].join("");
const COLLECTION_REF = ["/", PREFIX, "references"].join("");
const COLLECTION_SECRET = ["/", PREFIX, "secrets"].join("");

export const scopes = {
    getAll: () => {
        return new Promise<IScope[]>((resolve, reject) => {
            var q = query(ref(db, COLLECTION_META), orderByChild("removed"), equalTo(null));
            onValue(
                q,
                (snapshot) => {
                    const raw = snapshot.val();
                    const scopes = Object.keys(raw).map<IScope>((k) => new Scope(k, raw[k].title));

                    resolve(scopes);
                },
                reject
            );
        });
    },
    exists: async (scopeId: string) => {
        const snap = await get(ref(db, COLLECTION_META + `/${scopeId}`));
        return snap.exists();
    },
    insert: async (scope: ScopeRecord) => {
        if (scope == null || scope.id == null || scope.title == null)
            throw new CError(INVALID_INPUT_DATA);
        if (scope.id.length === 0 || scope.title.length === 0) throw new CError(INVALID_INPUT_DATA);
        if (scope.id.indexOf(" ") !== -1)
            throw new CError(INVALID_INPUT_DATA, "'key' can not contain space");

        const newScope = ref(db, COLLECTION_META + `/${scope.id}`);
        if ((await get(newScope)).exists())
            throw new CError(DUPLICATED, `Key '${scope.id}' already exists`);

        return set(newScope, { title: scope.title });
    },
    update: async (key: string, title?: string) => {
        if (key == null || key.length === 0)
            throw new CError(INVALID_INPUT_DATA, "'key' can't be null or empty");

        const r = ref(db, COLLECTION_META + `/${key}`);
        if ((await get(r)).exists() === false) throw new CError(NOTFOUND, `Key '${key}' not found`);

        return update(r, { title: title });
    },
    remove: async (key: string) => {
        const tobeRmRef = ref(db, COLLECTION_META + `/${key}`);
        return update(tobeRmRef, removed_indices);
    },
    on: (
        onUpdated: SAction<DataSnapshot>,
        onCanceled?: SAction<Error> | undefined,
        path?: string | null | undefined
    ) => {
        let _path = COLLECTION_META;
        if (path != null && path.length > 0) _path += "/" + path;

        var q = query(ref(db, _path), orderByChild("removed"), equalTo(null));
        return onValue(q, onUpdated, onCanceled);
    },
    getMeta: async (scopeId: string): Promise<ScopeMeta> => {
        const snap = await get(ref(db, COLLECTION_META + `/${scopeId}`));
        if (snap.exists()) return snap.val() as ScopeMeta;

        throw new CError(NOTFOUND, `Template not found in scope '${scopeId}'`);
    },
};

export const templates = {
    getAll: () => {
        throw new Error("Not implemented");
    },
    getByScope: async (scopeId: string): Promise<string> => {
        const snap = await get(ref(db, COLLECTION_TEMPLATE + `/${scopeId}`));
        if (snap.exists()) return snap.val();

        throw new CError(NOTFOUND, `Template not found in scope '${scopeId}'`);
    },
    update: async (scopeId: string, template?: string) => {
        const r = ref(db, COLLECTION_TEMPLATE);
        return update(r, { [scopeId]: template });
    },
    on: (
        onUpdated: SAction<DataSnapshot>,
        onCanceled?: SAction<Error> | undefined,
        path?: string | null | undefined
    ) => {
        let _path = COLLECTION_TEMPLATE;
        if (path != null && path.length > 0) _path += "/" + path;

        var q = query(ref(db, _path));
        return onValue(q, onUpdated, onCanceled);
    },
};

export const references = {
    getAll: () => {
        throw new Error("Not implemented");
    },
    getByScope: async (scopeId: string): Promise<IValue[]> => {
        return new Promise<IValue[]>((resolve, reject) => {
            var q = query(ref(db, COLLECTION_REF + `/${scopeId}`));
            onValue(
                q,
                (snapshot) => {
                    const raw = snapshot.val();
                    if (raw == null) return resolve([]); // Empty

                    const values = Object.keys(raw).map<IValue>((k) => ({
                        key: k,
                        value: raw[k],
                    }));

                    resolve(values);
                },
                reject
            );
        });
    },
    update: async (scopeId: string, { key, value }: IValue) => {
        const r = ref(db, COLLECTION_REF + `/${scopeId}`);
        return update(r, { [key]: value });
    },
    exists: async (scopeId: string, key: string) => {
        const snap = await get(ref(db, COLLECTION_REF + `/${scopeId}/${key}`));
        return snap.exists();
    },
    remove: async (scopeId: string, key: string) => {
        const tobeRmRef = ref(db, COLLECTION_REF + `/${scopeId}/${key}`);
        return remove(tobeRmRef);
    },
};

export const secrets = {
    getAll: () => {
        throw new Error("Not implemented");
    },
    getByScope: async (scopeId: string): Promise<IValue[]> => {
        return new Promise<IValue[]>((resolve, reject) => {
            var q = query(ref(db, COLLECTION_SECRET + `/${scopeId}`));
            onValue(
                q,
                (snapshot) => {
                    const raw = snapshot.val();
                    if (raw == null) return resolve([]); // Empty

                    const values = Object.keys(raw).map<IValue>((k) => ({
                        key: k,
                        value: raw[k],
                    }));

                    resolve(values);
                },
                reject
            );
        });
    },
    getByKey: async (scopeId: string, key: string) => {
        return new Promise<string>((resolve, reject) => {
            var q = query(ref(db, COLLECTION_SECRET + `/${scopeId}/${key}`));
            onValue(
                q,
                (snapshot) => {
                    const raw = snapshot.val();
                    if (raw == null) return resolve(""); // Empty
                    resolve((raw as string) || "");
                },
                reject
            );
        });
    },
    update: async (scopeId: string, { key, value }: IValue) => {
        const r = ref(db, COLLECTION_SECRET + `/${scopeId}`);
        return update(r, { [key]: value });
    },
    exists: async (scopeId: string, key: string) => {
        const snap = await get(ref(db, COLLECTION_SECRET + `/${scopeId}/${key}`));
        return snap.exists();
    },
    remove: async (scopeId: string, key: string) => {
        const tobeRmRef = ref(db, COLLECTION_SECRET + `/${scopeId}/${key}`);
        return remove(tobeRmRef);
    },
    on: (
        onUpdated: SAction<DataSnapshot>,
        onCanceled?: SAction<Error> | undefined,
        path?: string | null | undefined
    ) => {
        let _path = COLLECTION_SECRET;
        if (path != null && path.length > 0) _path += "/" + path;

        var q = query(ref(db, _path));
        return onValue(q, onUpdated, onCanceled);
    },
};
